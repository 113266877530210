const data = [
  {
    id: 1,
    img: "https://res.cloudinary.com/dpkdi73b4/image/upload/v1709525215/jgay3mtdx8i9l3dcm2sc.png",
    name: "Blink Chat",
    disc: "A MERN Stack based Application empowered by the power of Socket.Io to enable a real-time messaging system that allows construction and maintains single and group chats easily",
    techstack: "MERN",
    database: "MongoDB",
    backend: "Node js",
    frameworks: [
      "Express JS",
      "Socket Io",
      "Chakra UI",
      "JWT",
      "Mongoose",
      "Node Mailer",
    ],
    github: "https://github.com/harsha-pingali/blink_chat_frontend",
    link: "https://blinkchatweb.vercel.app/",
  },
  {
    id: 2,
    img: "https://res.cloudinary.com/dpkdi73b4/image/upload/v1709526435/fpxlloin9yrnabytibys.png",
    name: "Food Express..",
    disc: "Developed an application based on Spring Boot to implement an online food delivery system with the provision for the restaurants to update the Menus and also implemented microservices architecture using Eureka",
    github: "https://github.com/harsha-pingali/Jfsd_sdp",
    techstack: "JFSD",
    database: "My SQL",
    backend: "Java",
    frameworks: [
      "Spring Boot",
      "Spring Data JPA",
      "Spring Boot Starter Mail",
      "Stripe",
      "Ajax",
      "BootStrap",
      "Microservices Using Eureka",
    ],
  },
  {
    id: 3,
    img: "https://res.cloudinary.com/dpkdi73b4/image/upload/v1709531902/m3pjiwsu2nxlkaydrxei.jpg",
    name: "Text-To-Speech Converter",
    disc: "Developed a simple Serverless web application using React js & AWS Lambda that converts Text to speech utilizing AWS Poly",
    // link :"https://blinkchatweb.vercel.app/",
    techstack: "React + AWS",
    backend: "Node js (AWS Lambda runtime)",
    frameworks: [
      "Chakra Ui",
      "AWS services like IAM, Lambda, Poly, API Gateway",
      "AWS IAM",
      "AWS Lambda",
      "Amazon Poly",
    ],
  },
  {
    id: 4,
    img: "https://res.cloudinary.com/dpkdi73b4/image/upload/v1709691065/ymeqlj2xor6v7e2ynroi.jpg",
    name: "Weather App",
    disc: "Built a simple yet efficient application using HTML, CSS & JS and utilized weather API to fetch the data",
    // link :"/",
    techstack: "HTML + CSS + JS",
    frameworks: ["Rapid API (To fetch weather data)", "Bootstrap"],
  },
];
export default data;
